@use 'src/assets/styles/palette' as palette;

.cs_sidebar {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 0 20px 32px;
    &__title {
        display: block;
        padding-bottom: 8px;
    }
    &__item {
        padding: 5px 0;
    }
    &__btn {
        padding: 10px 0 0;
        color: palette.$label_secondary;
        background: none;
        border: none;
        cursor: pointer;

        &:hover {
            color: palette.$label_brand;
        }

        &:active {
            color: palette.$label_brand;
        }
    }
}
