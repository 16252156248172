@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.banner {
  display: flex;
  gap: 20px;
  flex: 1 0 0;
  @media (max-width: breakpoints.$tablet-upper-boundary) {
    gap: 30px;
    flex: inherit;
    flex-direction: column;

  }
  &_wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 20px 24px;
    color: var(--help-color, palette.$interface_onsuface);
    background: palette.$interface_ad_primary;
    border-radius: 12px;
    backdrop-filter: blur(15px);
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
      align-items: flex-end;
      padding: 60px;
      border-radius: 26px;
    }
  }

  &:before {
    content: 'mega offer';
    position: absolute;
    top: 14px;
    right: -210px;
    z-index: -1;
    width: 120%;
    font-size: 148px;
    font-weight: 700;
    line-height: 1.5;
    color: var(--item-c, #1700AC);

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      top: -150px;
      right: -195px;
      z-index: -1;
      font-size: 305px;
    }

  }

  &_description {
    display: grid;
    gap: 20px;
    width: 100%;
  }

  &_text {
    font-size: 22px;
    font-weight: 400;
  }
}

.buttons {
  display: flex;
  flex-direction: column-reverse;
}
