@use 'src/assets/styles/palette' as palette;

.paragraph {
  margin-bottom: 20px;
}

.list_decimal {
  list-style-type: decimal;
}

.sub_title {
  color: palette.$label_quaternary;
}

.list {
  padding: 20px;
}
