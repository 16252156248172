@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.amount {
  display: flex;
  align-items: center;
  // flex: 1 0 0;
  background: palette.$background_quaternary;
  border: 2px solid palette.$background_quaternary;
  border-radius: 26px;
  overflow: hidden;

  &__right {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 24px;
    background: palette.$background_primary;
    border-radius: 26px;
    z-index: 1;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__left {
    display: block;
    width: 50px;
    height: 100%;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 100px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 117px;
      height: 117px;
      left: -22px;
      top: 37px;
      background: url('../../../../assets/images/email.svg') center bottom no-repeat;
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &_text {
      color: palette.$label_tertiary;
    }
  }
}


