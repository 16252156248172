@use 'breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.page {
    display: grid;
    align-items: flex-start;
    &_aside {
        &-left {
            @media (min-width: breakpoints.$desktop-lower-boundary) {
                grid-template-columns: 200px 1fr;
                grid-template-areas: 'title title' 'aside content';
                column-gap: 40px;
            }
            @media (max-width: breakpoints.$tablet-upper-boundary) {
                grid-template-areas: 'title' 'info' 'content';
            }

            &_account {
                @media (min-width: breakpoints.$desktop-lower-boundary) {
                    grid-template-columns: 320px 1fr;
                }
            }
        }
    }
    &__content {
        grid-area: content;
        width: 100%;
        min-width: 0;

        &_container {
            overflow: hidden;
            @media (max-width: breakpoints.$tablet-upper-boundary) {
                margin: 0 24px;
            }
        }
    }
    &__aside {
        z-index: 1;
        grid-area: aside;
        margin: var(--page-aside-m);
        &_sticky {
            @media (min-width: breakpoints.$desktop-lower-boundary) {
                position: sticky;
                top: 20px;
            }
        }
        &_account {
            display: flex;
            gap: 20px;
            flex-direction: column;
            margin-bottom: 20px;
        }
    }
    &__blank {
        display: flex;
        gap: 30px;
        flex-direction: column;

        @media (min-width: breakpoints.$desktop-lower-boundary) {
            position: sticky;
            gap: 40px;
        }
    }

    &__error_container {
        margin: 40px 20px;
    }
}

.brand_text {
    color: palette.$label_brand;
}

.text_center {
    text-align: center;
}
