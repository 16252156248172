@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.landing {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 138px);
}
.content {
  display: grid;
  gap: 20px;
  justify-content: center;
  height: fit-content;
  margin: 60px 24px;
  text-align: center;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: auto;
  }

  &_text {
    font-size: 26px;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      overflow: hidden;
      font-size: 20px;
      text-overflow: ellipsis;
    }
  }

  &_btns {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 20px;
      flex-direction: initial;
      margin-top: 30px;
    }
  }

  &_loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
    }
  }


}

.brand_text {
  color: palette.$label_brand;
}

.loader {
  position: relative;
  width: 5px;
  height: 5px;
  margin-top: 14px;
  margin-left: 15px;
  text-align: center;
  background-color: palette.$label_brand;
  border-radius: 50%;
  box-shadow: 10px 0 #fff2, -10px 0 #fff2;
  animation: loading-animation 0.5s ease-out infinite alternate;
}

@keyframes loading-animation {
  0% {
    background-color: #fff2;
    box-shadow: 10px 0 #fff2, -10px 0 palette.$label_brand;
  }
  50% {
    background-color: palette.$label_brand;
    box-shadow: 10px 0 #fff2, -10px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 10px 0 palette.$label_brand, -10px 0 #fff2;
  }
}

.inmage {
  margin: 0 auto;
}
