@use 'assets/styles/palette' as palette;

@import 'assets/styles/font-face';
@import 'assets/styles/typography';
@import 'assets/styles/page';
@import 'assets/styles/loader';
@import 'assets/styles/controls';
@import 'assets/styles/ui-kit-custom-theme';
// @import 'assets/styles/_constants';

@import '@design-system/ui-kit/dist/main.css';

* {
  margin: 0;

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

.word-break {
  word-break: break-word;
}
