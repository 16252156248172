@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.main {
  display: grid;
  gap: 40px;
  justify-content: space-between;
  padding: 20px 24px 40px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    display: flex;
    align-items: center;
    padding: 40px 0;
  }

  &_wrapper {
    // overflow: hidden;
    position: relative;
    height: 100%;
    &:before {
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 788px;
        background: url('../../../../assets/images/landing/background.svg') top right no-repeat;

      }
    }
    &_directV2 {
      background: #f3f4fc;
    }
    &_welcome {
      background: palette.$background_primary;
    }

    &_takeAdsV1, &_takeAdsV2 {
      &:before {
        display: none;
      }
    }
  }
}
.btns {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
