@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content {
  display: flex;
  gap: 60px;
  flex-direction: column;
  padding: 20px 24px 40px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    padding: 60px 0;
  }

  &_list {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: center;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &_img {
    margin-bottom: 10px;
  }

  &_item {
    display: grid;
    gap: 10px;
    align-items: center;
    justify-items: center;
    width: auto;
    text-align: center;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 300px;
    }
  }
}
