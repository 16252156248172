@use 'src/assets/styles/palette' as palette;
$borderRadius_s: 4px;
$borderRadius_m: 6px;
$borderRadius_l: 8px;

$strip_gap: 6px;

.notificationItem {
  box-sizing: border-box;
  width: 100%;
  border-radius: $borderRadius_m;
  position: relative;
  padding: 15px 24px 14px;
}

.content > *:not(:first-child) {
  margin-top: 10px;
}

.coloredStrip {
  width: 3px;
  position: absolute;
  top: $strip_gap;
  left: $strip_gap;
  bottom: $strip_gap;
  border-radius: $borderRadius_s;
}

.cross {
  right: 0;
  top: 0;
}

.theme {
  &_info {
    .coloredStrip {
      background-color: palette.$interface_ad_primary;
    }
  }

  &_critical {
    .coloredStrip {
      background-color: #FF7253;
    }
  }

  &_success {
    .coloredStrip {
      background-color: #2FD48F;
    }
  }

  &_warning {
    .coloredStrip {
      background-color: #FFBF42;
    }
  }
}

.background {
  &_black {
    background-color: #282828;
  }

  &_white {
    background-color: palette.$background_primary;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.05), 0 5px 10px rgba(0, 0, 0, 0.08);
  }
}

.closeButton {
  background: none;
  border: none;
  line-height: 0;
  padding: 0;
  position: relative;
  color: palette.$interface_tertiary;
}

.textTitle {
  color: palette.$background_primary;;
}
.textDescription {
  color: palette.$background_primary;;
}
