@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.promo {
  display: flex;
  gap: 10px;
  flex-direction: column;
  color: palette.$label_secondary;

  &__title {
    color: palette.$label_tertiary;
  }

  &_add {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 10px;
    width: 100%;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 10px;
      flex-direction: initial;
      width: 400px;
    }

    &__btn {
      height: fit-content;
    }

    &__field {
      flex: 1 0 0;
    }
  }
}

.promocodes {
  display: flex;
  gap: 10px;
  flex-direction: column;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    gap: 12px;
    margin-top: 10px;
  }
}

.content {
  margin-bottom: 30px;
}




