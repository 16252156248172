@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: palette.$label_secondary;
  text-decoration: none;
  background: palette.$background_primary;
  border: 2px solid #F2F3FF;
  border-radius: 26px;
  cursor: pointer;

  &__special {
    background: palette.$interface_ad_primary;
    border-color: palette.$interface_ad_primary;
  }

  &:focus-visible {
    outline: 2px solid palette.$interface_focus_primary;
  }
}
