@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

.categoriesMenu {
    position: fixed;
    bottom: 0;
    z-index: 3;
    width: 100%;
    background: rgba(255, 255, 255, 0.40);
    box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05), 0px -4px 20px 0px rgba(0, 0, 0, 0.07), 0px -1px 2px 0px rgba(0, 0, 0, 0.03);
    backdrop-filter: blur(15px);

    &__wrapper {
        flex-shrink: 0;
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 12px 20px;
    }

    &__searchButton {
        position: relative;
        padding: 0;
        line-height: 0;
        background: none;
        border: none;
    }

    &__hr {
        width: 100%;
        height: 1px;
        margin: 20px 0;
        background: palette.$interface_sixth;
        border: none;
        border-radius: 3px;
    }

    &__category_list {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &_link {
            width: 100%;
            padding: 13px 24px;
            border-bottom: 1px solid palette.$interface_sixth;

            &:last-child {
                border: none;
            }
        }
    }


}
