@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.fssBlock {
    display: grid;
    grid-template-areas: 'search sort';
    gap: 10px 20px;
    justify-content: var(--fssBlock-jc, space-between);
    padding: 40px 24px 30px 24px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
        grid-template-columns: 560px 250px;
        padding: 0;
    }
    &_withAdditionalFilter {
        --fssBlock-jc: flex-start;
    }
}

.fssBlockSort {
    grid-area: sort;
}

.fssBlockSearch {
    grid-area: search;
}

.fssOrderList {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &_link {
        width: 100%;
        padding: 13px 24px;
        border-bottom: 1px solid palette.$interface_sixth;

        &:last-child {
            border: none;
        }
    }
}
