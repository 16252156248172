@use 'src/assets/styles/breakpoints' as breakpoints;
@use "src/assets/styles/palette" as palette;

.breadcrumbs {
  font-size: 11px;
  font-weight: 500;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 24px 40px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    padding: 0 0 40px;
}
}

.link {
  color: palette.$label_quaternary;
  text-decoration: none;

  &:hover {
    color: palette.$interface_ad_primary;
  }
}

.arrow {
  width: 8px;
}
