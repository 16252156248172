$zIndex_darkClickableArea: 100;

.darkClickableArea {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $zIndex_darkClickableArea;

  &_fixed {
    position: fixed;
  }

  &:before {
    display: block;
    content: '';
    background: #282828;
    opacity: 0.4;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: $zIndex_darkClickableArea;
  }

  & > * {
    z-index: $zIndex_darkClickableArea + 1;
  }
}

