@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.avatar {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: var(--avatar-size, 44px);
    height: var(--avatar-size, 44px);
    min-height: var(--avatar-size, 44px);
    min-width: var(--avatar-size, 44px);
    font-size: var(--avatar-fz, 18px);
    font-weight: 600;
    line-height: 1.2;
    color: #fff;
    text-transform: uppercase;
    background: palette.$interface_ad_primary;
    border-radius: 50%;

    &:before {
        content: ' ';
        position: absolute;
        width: 24px;
        height: 24px;
    }

    &_type_search {
        &:before {
            background: url('../../assets/images/icons/search.svg') center bottom no-repeat;
        }
    }

    &_type_logo {
        &:before {
            background: url('../../assets/images/header/user-logo.svg') center bottom no-repeat;
        }
    }

}
