@use 'src/assets/styles/palette' as palette;

$outline-width: 2px;

.tabList {
    position: relative;
    overflow-x: auto;
    display: flex;
    margin: 20px 0;
    padding: $outline-width;
    white-space: nowrap;
    &::after {
        content: '';
        position: absolute;
        right: $outline-width;
        bottom: $outline-width;
        left: $outline-width;
        border-bottom: 1px solid palette.$interface_fifth;
    }
}

.tabItem {
    position: relative;
    padding: 14px 24px;
    color: palette.$label_fifth;
    text-decoration: none;
    background: none;
    border: none;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 4px;
        background: none;
        border-radius: 2px 2px 0 0;
    }
    &:focus-visible {
        box-shadow: 0 0 0 $outline-width palette.$interface_focus_primary;
        outline: none;
    }
    &:hover {
        color: palette.$label_brand;
    }
    &_active {
        color: palette.$label_brand;

        &:hover {
            color: palette.$interface_ad_quaternary;
        }
        &::after {
            background: palette.$interface_ad_primary;
        }
        &:focus-visible {
            box-shadow: $outline-width (-1 * $outline-width) 0 0 palette.$interface_focus_primary, (-1 * $outline-width) (-1 * $outline-width) 0 0 palette.$interface_focus_primary;
        }
    }
}
