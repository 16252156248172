
$horizontalIndent: 20px;

.notifications {
  position: fixed;
  bottom: 20px;
  right: $horizontalIndent;
  width: calc(100vw - 2*#{$horizontalIndent});
  max-width: 327px;
  z-index: 999999;
}

.item:not(:first-child) {
  margin-top: 10px;
}
