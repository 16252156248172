@use 'src/assets/styles/palette' as palette;

.accordionItem {
  width: 100%;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    list-style: none;
    border-top: 1px solid palette.$interface_fifth;
    cursor: pointer;
    &::-webkit-details-marker {
      display:none;
    }
    &_without_border {
      border: none;
    }

    &:focus-visible {
      border: none;
      outline: 1px solid palette.$interface_focus_primary;
    }

    & > h3  {
      max-width: calc(100% - 60px);
    }
  
    & > img  {
      margin-left: auto;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 20px 20px 20px;
  }
}
