@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.help {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'one two' 'three four';
  gap: 10px 15px;
  width: 100%;
  row-gap: 15px;
  counter-reset: cards-item;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 'one two three four';
    gap: 20px;
  }

  &__wrapper {
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0 24px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 40px;
      margin: 0;
    }
  }

  &__title {
    color: palette.$label_tertiary;
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 10px;
    color: var(--help-color, palette.$interface_onsuface);
    background: var(--help-bg, #2302F5);
    border-radius: 12px;
    backdrop-filter: blur(15px);
    justify-content: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 45px 30px;
      border-radius: 26px;
    }

    &:before {
      content: counter(cards-item);
      position: absolute;
      top: var(--item-top, -125px);
      right: var(--item-right, 13px);
      z-index: -1;
      font-size: 300px;
      font-weight: 700;
      line-height: 1.5;
      color: var(--item-c, #1700AC);
      counter-increment: cards-item;
    }

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        --item-right: -5px;
        --item-top: -108px;
      }
    &_one {
      cursor: pointer;
      @media (min-width: breakpoints.$tablet-lower-boundary) {
        grid-area: one;
      }
      @media (max-width: breakpoints.$phone-upper-boundary) {
        --item-right: 0;
        --item-top: -122px;
      }
    }

    &_two {
      @media (min-width: breakpoints.$tablet-lower-boundary) {
        grid-area: two;
      }
      --help-bg: rgba(236, 241, 255, 0.40);
      --help-color: palette.$label_primary;

      &:before {
        --item-right: -48px;
        --item-top: -116px;
        --item-c: #ededed;

        @media (max-width: breakpoints.$phone-upper-boundary) {
          --item-right: 0;
          --item-top: -216px;
        }
        @media (min-width: breakpoints.$desktop-lower-boundary) {
          --item-right: 0;
          --item-top: -125px;
        }
      }
    }

    &_three {
      @media (min-width: breakpoints.$tablet-lower-boundary) {
        grid-area: three;
      }
      @media (max-width: breakpoints.$tablet-upper-boundary) {
        --help-bg: rgba(236, 241, 255, 0.40);
        --help-color: palette.$label_primary;
      }
      &:before {
        --item-right: -9px;
        --item-top: -155px;
        @media (max-width: breakpoints.$tablet-upper-boundary) {
          --item-c: #ededed;
        }
        @media (min-width: breakpoints.$desktop-lower-boundary) {
          --item-right: 0;
          --item-top: -170px;
        }
      }
    }
    &_four {
      --help-bg: rgba(236, 241, 255, 0.40);
      --help-color: palette.$label_primary;
      @media (min-width: breakpoints.$tablet-lower-boundary) {
        grid-area: four;
      }
      @media (max-width: breakpoints.$tablet-upper-boundary) {
        --help-bg: #2302F5;
        --help-color: #fff;
      }
      &:before {
        --item-right: -23px;
        --item-top: -225px;

        @media (min-width: breakpoints.$desktop-lower-boundary) {
          --item-c: #ededed;
          --item-right: 0;
          --item-top: -170px;
        }
      }
    }
  }
}
