@use "src/components/Modal/Modal.module" as modal;
@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

$desktopPadding: 40px;
$mobilePadding: 20px;
$borderRadius: 26px;
$zIndex_modalWindow: 201;

.modalContent {
  position: relative;
  z-index: $zIndex_modalWindow;
  box-sizing: border-box;
  width: var(--model_width, 470px);;
  max-width: calc(100% - #{modal.$modal_indents * 2});
  height: fit-content;
  margin: modal.$modal_indents;
  padding: $desktopPadding;
  background: palette.$background_primary;
  border-radius: $borderRadius;

  @media all and (max-width: breakpoints.$phone-upper-boundary) {
    padding: $mobilePadding;
  }

  &_size_s {
    --model_width: 470px;
  }
  &_size_m {
    --model_width: 640px;
  }
  &_size_l {
    --model_width: 728px;
  }
}

.closeIcon {
  position: absolute;
  top: $desktopPadding;
  right: $desktopPadding;
  z-index: 1;

  @media all and (max-width: breakpoints.$phone-upper-boundary) {
    position: absolute;
    top: $mobilePadding;
    right: $mobilePadding;
  }
}

.header {
  position: relative;
  display: flex;
  margin-bottom: 20px;
}

.headerText {
  flex-grow: 1;
  display: flex;
}

.headerText_withMarginRight {
  margin-right: 30px;
}

.titleIcon {
  flex-shrink: 0;
  margin-right: 10px;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 0;
  line-height: 0;
  color: #8c8691;
  background: none;
  border: none;
  cursor: pointer;
}
