@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.landing {
  display: flex;
  justify-content: center;
  height: 100%;
  min-height: calc(100vh - 138px);
}
.content {
  display: grid;
  gap: 20px;
  justify-content: center;
  height: fit-content;
  margin: 60px 24px;
  text-align: center;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: auto;
  }

  &_text {
    font-size: 26px;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      overflow: hidden;
      font-size: 20px;
      text-overflow: ellipsis;
    }
  }

  &_btns {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 20px;
      flex-direction: initial;
    }
  }

  &_loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
    }
  }


}
.info {
  margin-top: 45px;

  &_title {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  &_text {
    max-width: 100%;
    font-size: 16px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      max-width: 540px;
    }

    &_wrap {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }
}
.description {
  display: grid;
  margin: 40px 24px 30px;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: 50px 0 40px;
  }
}

.brand_text {
  color: palette.$label_brand;
}

.stores {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin: 0 24px 40px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 30px;
    margin: 0 0 120px;
  }
}

.storeCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border: 1px solid palette.$interface_fifth;
  border-radius: 26px;

  &_logo_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 68px;
  }
  &_logo {
    max-width: 160px;
    max-height: 68px;
  }
}

.loader {
  position: relative;
  width: 9px;
  height: 9px;
  margin-top: 35px;
  margin-left: 30px;
  text-align: center;
  background-color: palette.$label_secondary;
  border-radius: 50%;
  box-shadow: 18px 0 #fff2, -18px 0 #fff2;
  animation: loading-animation 0.5s ease-out infinite alternate;
}

.cashbackText {
  margin-top: 20px;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin-top: 40px;
  }
}

@keyframes loading-animation {
  0% {
    background-color: #fff2;
    box-shadow: 18px 0 #fff2, -18px 0 palette.$label_secondary;
  }
  50% {
    background-color: palette.$label_secondary;
    box-shadow: 18px 0 #fff2, -18px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 18px 0 palette.$label_secondary, -18px 0 #fff2;
  }
}
