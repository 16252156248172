@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.modal {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
        width: 375px;
    }
    &_wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 20px;
        background: palette.$background_primary;
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.14);
        z-index: 201;
        width: 100%;

        @media (min-width: breakpoints.$desktop-lower-boundary) {
            left: 33px;
            bottom: 55px;
            width: inherit;
        }
    }
}
.header {
    display: flex;
    padding: 8px 15px;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    background: palette.$background_primary;
}
.content {
    display: flex;
    padding: 0 40px 40px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;

    &_step {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 45px;
    }

    &_step_text {
        color: palette.$label_quaternary;
        font-size: 14px;
        line-height: 1.3;
    }

    &_main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        text-align: left;
    }
}

.close_button {
    position: relative;
    padding: 0;
    line-height: 0;
    color: palette.$interface_tertiary;
    background: none;
    border: none;
}

.arrow_button {
    position: relative;
    padding: 0;
    line-height: 0;
    color: palette.$label_brand;
    background: none;
    border: none;
}

.brand_text {
    color: palette.$label_brand;
}
