@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.main {
  position: relative;
  z-index: 2;
  display: grid;
  gap: 20px;
  place-items: center;
  padding: 0;
  text-align: center;
  &_logo {
    cursor: pointer;
  }
  &_wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 20px 24px 40px;
    background-color: #f3f4fc;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 100px 260px 260px 260px;
    }
    &:before {
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        content: "mega offer";
        position: absolute;
        top: -150px;
        right: 0;
        width: 100%;
        opacity: 0.02;
        font-size: 283px;
        font-weight: 700;
        line-height: 1.5;
        color: #1700ac;
      }
    }
  }
}

.search {
  &_form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: inherit;
      margin-top: 60px;
    }
  }
  &_input {
    width: 100%;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      position: relative;
      flex-grow: 1;
      width: min-content;
    }
  }
  &_item {
    padding: 13px 20px;
  }
}

.input {
  :global(.placeholder_20b) {
    max-width: calc(100% - 28px);
    overflow: hidden;
  }
  :global(.placeholderText_3GM) {
    display: inline-block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
