@use 'src/assets/styles/palette' as palette;

.action_card_header {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;


  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.amount_text {
  color: palette.$label_tertiary;
  text-align: right;

  &__approved {
    color: palette.$label_brand;
    text-align: right;
  }

  &__declined {
    color: palette.$label_error;
    text-align: right;
  }
}


