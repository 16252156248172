@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;


.method {
  display: flex;
  gap: 20px;
  flex-direction: column;

  &__title {
    color: palette.$label_tertiary;
  }

  &__type_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;


    @media (min-width: breakpoints.$desktop-lower-boundary) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  &__type {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border: 3px solid palette.$interface_quaternary;
    border-radius: 8px;
    cursor: pointer;
    background: none;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      &:first-child {
        grid-column: 1 / 3;
      }
    }

    &_coming_soon {
      width: 100%;
      font-size: 8px;
      font-weight: 500;
      color: palette.$interface_secondary;
      text-align: center;
      background: rgba(224, 224, 224, 0.20);
      padding: 2px 6px;
    }

    &:hover {
      color: palette.$interface_tertiary;
      border-color: palette.$interface_tertiary;
    }

    &_active {
      color: palette.$interface_ad_primary;
      border-color: palette.$interface_ad_primary;

      &:hover {
        color: palette.$interface_ad_tertiary;
        border-color: palette.$interface_ad_tertiary;
      }
    }
    &_disabled {
      display: flex;
      gap: 4px;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0 0;
      color: palette.$interface_fifth;
      background: palette.$background_secondary;
      border-color: palette.$interface_fifth;
      cursor: not-allowed;
      &:hover {
        color: palette.$interface_fifth;
        border-color: palette.$interface_fifth;
      }
    }
  }
}


