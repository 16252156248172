@use 'src/assets/styles/palette' as palette;

.action_status {
  display: flex;
  gap: 6px;
  align-items: center;

  &__text {
    &_approved, &_declined {
      color: palette.$label_primary;
    }

    &_pending {
      color: palette.$label_quaternary;
    }
  }
}


