@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;


.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin: 0 24px 20px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: 0 0 20px;
  }

  &__sub_title {
    color: palette.$label_tertiary;
  }

  &__mb_20 {
    margin-bottom: 20px;
  }
}
