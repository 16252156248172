@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.action_card_body {
  align-self: stretch;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background: palette.$background_primary;
  border-radius: 26px;

  &__content {
    align-self: stretch;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__date {
    color: palette.$label_quaternary;
  }

  &__desc {
    color: palette.$label_tertiary;
  }

}
