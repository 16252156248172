@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.search {
  display: flex;
  gap: 20px;
  align-items: center;

  &_btn {
    color: palette.$label_fifth;
    background: none;
    border: none;
    cursor: pointer;
  }

  &_item {
    padding: 13px 20px;
  }
}
