$font-styles-poppins: 'regular', 'medium', 'semiBold', 'bold', 'black';
$font-styles: 'regular', 'medium', 'bold', 'black';
$font-poppins: (
        'regular': 400,
        'medium': 500,
        'semiBold': 600,
        'bold': 700,
        'black': 900,
);
$font: (
        'regular': 400,
        'medium': 500,
        'bold': 700,
        'black': 900,
);

@for $i from 1 through length($font-styles) {
  $font-style: nth($font-styles, $i);
  $font-weight: map-get($font, $font-style);
  @font-face {
    font-family: 'Inter';
    src: url('../font/inter-' + $font-style + '.woff2') format('woff2');
    font-style: normal;
    font-weight: $font-weight;
    font-display: swap;
  }
}

@for $i from 1 through length($font-styles-poppins) {
  $font-style-poppins: nth($font-styles-poppins, $i);
  $font-weight-poppins: map-get($font-poppins, $font-style-poppins);
  @font-face {
    font-family: 'Poppins';
    src: url('../font/poppins-' + $font-style-poppins + '.woff2') format('woff2');
    font-style: normal;
    font-weight: $font-weight-poppins;
    font-display: swap;
  }
}
