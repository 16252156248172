@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content {
  overflow: hidden;
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
}
.controls {
  position: absolute;
  top: 400px;
  z-index: 1;
  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    top: 300px;
  }
}
.controls_prev {
  left: 0;
}

.controls_next {
  right: 24px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    right: 0;
  }
}
.banner {
  width: 100%;
  height: 710px;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    height: 520px;
    padding: 0 100px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 55px;
    bottom: 0;
    width: 55%;
    height: 100%;


    @media (max-width: breakpoints.$desktop-02-upper-boundary) {
      right: 50px;
      width: 50%;
    }

    @media (max-width: breakpoints.$desktop-01-upper-boundary) {
      right: 25px;
      width: 45%;
    }
    @media (max-width: breakpoints.$tablet-upper-boundary) {
      bottom: 30px;
      width: 100%;
      height: 60%;
    }

  }
  &_content {
    display: grid;
    gap: 10px;
    width: 100%;
    padding: 0;
    color: palette.$label_primary;
    text-align: left;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 585px;
      padding: 50px 20px;
    }

    &_text {
      font-size: 48px;
      font-weight: 700;
      line-height: 1.1;
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        font-size: 60px;
      }
    }

    &_desc {
      width: 100%;
      font-size: 18px;
      line-height: normal;

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        width: 85%;
        font-size: 26px;
      }
    }
  }
  &_footer {
    display: flex;
    margin-top: 10px;
  }

  &_brand_color {
    color: palette.$label_brand;
  }
}

.banner_first {
  position: relative;
  &:before {
    background: url('../../../../assets/images/banners/banner-first.webp') bottom right no-repeat;
    background-size: 115%;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      background-position: center;
      background-size: 100%;
    }
  }

}

.banner_second {
  position: relative;
  &:before {
    height: 520px;
    background: url('../../../../assets/images/banners/banner-second.webp') bottom right no-repeat;
    background-position: center;
    background-size: 100%;

    @media (max-width: breakpoints.$desktop-02-upper-boundary) {
      width: 43%;
    }

    @media (max-width: breakpoints.$desktop-01-upper-boundary) {
      width: 40%;
      height: 115%;
    }
    @media (max-width: breakpoints.$tablet-upper-boundary) {
      bottom: 30px;
      width: 100%;
      height: 60%;
    }
  }

}

