@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.catalog {
   &__title {
       grid-area: title;
       padding: 20px 24px 0 24px;

       @media (min-width: breakpoints.$desktop-lower-boundary) {
           padding: 60px 0 ;
       }
   }

  &__offers {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 15px;
    flex-direction: column;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      grid-template-columns: 1fr 1fr 1fr;
      row-gap: 40px;
    }
  }

  &__categories {
    overflow-x: auto;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    max-width: 100%;
  }

  &__stores {
    overflow-x: auto;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    max-width: 100%;
  }
}
