@use 'src/assets/styles/palette' as palette;

.supportMessage {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: palette.$label_tertiary;
  border: 2px solid palette.$interface_ad_primary;
  border-radius: 14px;
  &_title {
    color: palette.$background_seventh;
  }
}

