@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.banner {
  position: relative;
  margin: 0;
  background: url('../../../../assets/images/banners/banner-narrow-bg.png') left center /100% no-repeat;
  border-radius: 26px;
  cursor: pointer;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    margin: 0 24px;
    background: #eef4ff;
  }
}

.content {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin-left: 125px;
  padding: 30px 20px;
  color: palette.$label_primary;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    flex-direction: column;
    margin: 0;
    padding: 20px 15px 0 20px;
    text-align: left;
  }
}

.buttons {
  display: flex;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}

.image {
  position: absolute;
  top: -10px;
  left: 25px;
}
