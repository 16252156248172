@use 'src/assets/styles/breakpoints' as breakpoints;

.content {
  align-self: stretch;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    flex-direction: initial;
  }
}
