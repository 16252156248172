@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;


.mobile_overlay {
  position: fixed;
  top: 0;
  z-index: 101;
  float: right;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-height: none;
  background: #fff;
  overflow-y: auto;

  &__wrapper {
    align-self: stretch;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__closeButton {
    position: relative;
    padding: 0;
    line-height: 0;
    color: palette.$interface_tertiary;
    background: none;
    border: none;

  }

  &__header {
    align-self: stretch;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 0 24px;
  }
}
