@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.dialog {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
}

.search {
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media (max-width: breakpoints.$phone-lower-boundary) {
    gap: 16px;
  }

  &_input {
    position: relative;
    flex-grow: 1;
    width: min-content;

    :global(.placeholder_20b) {
      max-width: calc(100% - 28px);
      overflow: hidden;
    }

    :global(.placeholderText_3GM) {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    :global(.icon_Gpy) {
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &_wrap {
    width: 100%;
    padding-top: 20px;
  }

  &_item {
    padding: 13px 20px;
    cursor: pointer;
  }
}
