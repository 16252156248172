@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.block {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px 0;
  margin: 0 24px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    gap: 40px;
    padding: 60px 0;
    margin: 0;

  }
  &_without_indent {
    padding: 0 0 60px 0;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 0 0 40px 0;
    }
  }
  &_title {
    color: palette.$label_tertiary;
  }
  &_wrap {
    &_theme {
      &_dark {
        background: #f3f4fc;
      }
    }
  }
}
