@use "src/assets/styles/breakpoints" as breakpoints;
@use "src/assets/styles/palette" as palette;

.error {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 40px;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    flex-direction: column-reverse;
  }
}

.content,
.image {
  width: 50%;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    width: 100%;
    max-width: 100%;
  }
}

.content {
  max-width: 600px;
}

.image {
  line-height: 0;

  img {
    max-width: 100%;
  }
}

.title {
  margin: 0 0 30px;
  color: palette.$label_primary;
}

.text {
  margin: 0 0 20px;
  color: palette.$label_tertiary;

  a {
    color: palette.$interface_ad_primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.buttons {
  display: flex;
  gap: 10px;
}

.image {
  text-align: center;
}
