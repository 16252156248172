@use 'src/assets/styles/palette' as palette;

.lang_selector {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;

  &_btn {
    display: flex;
    align-items: center;
    padding: 0;
    color: palette.$label_quaternary;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;

    &:hover, &:focus, &:active {
      color: palette.$label_brand;
    }
  }

  &_text {
    color: palette.$label_tertiary;
  }
}

.lang_list {
  display: grid;

  &_btn {
    padding: 10px 20px;
    color: palette.$label_quaternary;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;

    &:hover, &:focus, &:active {
      color: palette.$interface_secondary;
      background: palette.$background_tertiary;
    }
  }
}
