@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.registration {
  position: relative;
  z-index: 2;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px;
  background: palette.$background_primary;
  border: 3px solid #030303;
  border-radius: 20px;
  backdrop-filter: blur(20px);

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    width: 460px;
    margin: 0;
    padding: 40px;
  }

  @media (max-width: 417px) {
    padding: 27px;
  }

  &_link {
    background: none;
    border: none;
    color: palette.$label_brand;
    text-decoration: initial;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.header {
  display: grid;
  gap: 20px;
  width: 100%;
  margin-bottom: 15px;

  &_socials {
    display: flex;
    gap: 10px;
  }
  &_social_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 11px 20px;
    background: palette.$background_tertiary;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    &_google {
      &:hover {
        background: #ea4335;
        .header_social_google {
          background: url('../../../../assets/images/landing/gl-hover.svg') center bottom no-repeat;
        }
      }
      &:disabled {
        .header_social_google {
          background: url('../../../../assets/images/landing/gl-disable.svg') center bottom no-repeat;
        }
      }
    }
    &_facebook {
      &:hover {
        background: #4a67ad;
        .header_social_facebook {
          background: url('../../../../assets/images/landing/fb-hover.svg') center bottom no-repeat;
        }
      }
      &:disabled {
        .header_social_facebook {
          background: url('../../../../assets/images/landing/fb-disable.svg') center bottom no-repeat;
        }
      }
    }

    &_facebook {
      &:hover {
        background: #4a67ad;
        .header_social_facebook {
          background: url('../../../../assets/images/landing/fb-hover.svg') center bottom no-repeat;
        }
      }
      &:disabled {
        .header_social_facebook {
          background: url('../../../../assets/images/landing/fb-disable.svg') center bottom no-repeat;
        }
      }
    }
    &_tiktok {
      &:hover {
        background: #231E1b;
        .header_social_tiktok {
          background: url('../../../../assets/images/landing/tiktok-hover.svg') center bottom no-repeat;
        }
      }
      &:disabled {
        .header_social_tiktok {
          background: url('../../../../assets/images/landing/tiktok-disable.svg') center bottom no-repeat;
        }
      }
    }
  }
  &_social_google {
    width: 22px;
    height: 22px;
    background: url('../../../../assets/images/landing/gl-normal.svg') center bottom no-repeat;
  }
  &_social_facebook {
    width: 22px;
    height: 22px;
    background: url('../../../../assets/images/landing/fb-normal.svg') center bottom no-repeat;
  }
  &_social_tiktok {
    width: 22px;
    height: 22px;
    background: url('../../../../assets/images/landing/tiktok-normal.svg') center bottom no-repeat;
  }
}

.form {
  display: grid;
  gap: 20px;

  &__btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_terms {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    color: palette.$label_quaternary;
  }
}
