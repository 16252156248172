@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.super_info {
  overflow: hidden;
  padding: 20px 24px;
  border-radius: 26px;
  backdrop-filter: blur(15px);
  &_directV2 {
    background: palette.$background_primary;
  }
  &_welcome {
    background: rgba(236, 241, 255, 0.40);
  }
  &_takeAdsV1, &_takeAdsV2 {
    background: palette.$background_tertiary;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: url('../../../../assets/images/landing/flash-bg.svg') bottom right no-repeat;
  }
  &_title {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;

    &_text {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &_text {
    max-width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.join_info {
  padding: 20px 28px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  background: palette.$background_primary;
  border-radius: 26px;
  backdrop-filter: blur(15px);
  &_takeAdsV1, &_takeAdsV2 {
    background: palette.$background_tertiary;
    border-radius: 26px;
  }
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    text-align: left;
  }
}

.days_info {
  overflow: hidden;
  min-width: min-content;
  word-break: normal;
  padding: 20px 24px;
  color: palette.$label_onsurface;
  background: palette.$interface_ad_primary;
  border-radius: 26px;
  backdrop-filter: blur(15px);

  &_takeAdsV1, &_takeAdsV2 {
    color: palette.$label_brand;
    background: #f3f4fc;
    &:before {
      opacity: 0.05;
    }
  }

  &_de {
    word-break: break-word;
  }

  &:before {
    content: "7";
    position: absolute;
    bottom: -196.398px;
    left: 23px;
    z-index: -1;
    font-family: Poppins, sans-serif;
    font-size: 271.629px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -2.716px;
    color: #2307d8;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      right: 17px;
      bottom: -136.398px;
    }
  }
}

.info {
  display: flex;
  gap: 15px;
  flex-direction: column-reverse;
  width: auto;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    flex-direction: column;
    width: 592px;
  }
}

.info_first {
  display: flex;
  gap: 15px;
  flex-direction: column;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    flex-direction: initial;
  }
}
