@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

.header {
    display: flex;
    gap: 30px;
    align-items: center;
    padding: 14px 0;
    color: palette.$label_brand;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
        margin: 0 20px;
    }

    &_gray {
        padding: 14px 60px;
        background-color: #f3f4fc;
        @media (max-width: breakpoints.$tablet-upper-boundary) {
            margin: 0;
            padding: 14px 24px;
        }
    }
    &_logo {
        cursor: pointer;
    }
}
