@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.box {
  position: relative;
  z-index: 101;
  overflow: hidden;
  margin-top: 10px;
  background: palette.$background_primary;
  border-radius: 12px;
  cursor: pointer;
  filter: drop-shadow(0 4px 12px rgba(0, 0, 0, 0.08)) drop-shadow(0 1px 2px rgba(0, 0, 0, 0.04));

  &_kind {
    &_square {
      border-radius: 2px;
    }
    &_round {
      border-radius: 12px;
    }
  }

  &_absolute {
    position: absolute;
    width: max-content;
    @media (max-width: breakpoints.$tablet-upper-boundary) {
      right: 24px;
    }
  }
}
