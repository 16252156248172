@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.logo_text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 68px;
  color: palette.$label_primary;
}

.title_text {
  color: palette.$label_tertiary;
}

.description {
  color: palette.$label_quaternary;
}

