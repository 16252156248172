@use 'src/assets/styles/palette' as palette;

.offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;


  &__link {
    width: 100%;
    padding: 13px 24px;

    &:last-child {
      border: none;
    }
  }

  &__title {
    color: palette.$label_quaternary;
    padding: 0 24px 13px;
    font-weight: 600;
  }
}

.search {
  &_input {
    width: 100%;
    padding: 0 24px;

    :global(.placeholder_20b) {
      max-width: calc(100% - 28px);
      overflow: hidden;
    }

    :global(.placeholderText_3GM) {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :global(.icon_Gpy) {
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  &_title {
    margin: 0 24px 10px;
  }

  &_text {
    margin: 0 24px 20px;
    color: palette.$label_quaternary;
  }

}

.mms__hr {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background: palette.$interface_sixth;
  border: none;
  border-radius: 3px;
}
