@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.wrapper {
  display: grid;
  gap: 40px;
}
.main_wrapper {
  display: grid;
  gap: 16px;
}
.main {
  position: relative;
  display: grid;
  gap: 10px;
  align-items: center;
  justify-items: center;
  width: 100%;
  padding: 20px;
  background: palette.$background_tertiary;
  border: 1px solid palette.$label_sixth;
  border-radius: 26px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    padding: 105px 60px;
  }

  &_loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    text-align: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
      text-align: initial;
    }
  }

  &_btn {
    font-size: 18px;
    background: none;
    border: none;
    cursor: pointer;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      font-size: 22px;
    }
  }

  &_text {
    text-align: center;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      font-size: 22px;
    }
  }
}
.btns {
  display: grid;
  gap: 10px;
}

.loader {
  position: relative;
  width: 9px;
  height: 9px;
  margin-top: 35px;
  margin-left: 30px;
  text-align: center;
  background-color: palette.$label_secondary;
  border-radius: 50%;
  box-shadow: 18px 0 #fff2, -18px 0 #fff2;
  animation: loading-animation 0.5s ease-out infinite alternate;
}

@keyframes loading-animation {
  0% {
    background-color: #fff2;
    box-shadow: 18px 0 #fff2, -18px 0 palette.$label_secondary;
  }
  50% {
    background-color: palette.$label_secondary;
    box-shadow: 18px 0 #fff2, -18px 0 #fff2;
  }
  100% {
    background-color: #fff2;
    box-shadow: 18px 0 palette.$label_secondary, -18px 0 #fff2;
  }
}
