@use 'src/assets/styles/palette' as palette;

.btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
    padding: var(--btn-p, 11px 20px);
    font-size: var(--btn-fz, 15px);
    line-height: var(--btn-icon-size, 20px);
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: none;
    border: 1px solid;
    border-radius: var(--btn-bdrs, 8px);
    cursor: pointer;

    &_theme {
        &_fill {
            color: palette.$label_onsurface;
            background: palette.$interface_ad_primary;
            border-color: palette.$interface_ad_primary;
            &:hover {
                color: palette.$label_onsurface;
                background: palette.$interface_ad_secondary;
                border-color: palette.$interface_ad_secondary;
            }
            &:active {
                color: palette.$label_onsurface;
                background: palette.$interface_ad_tertiary;
                border-color: palette.$interface_ad_tertiary;
            }
            &:focus-visible {
                color: palette.$label_onsurface;
                background: palette.$interface_ad_primary;
                border-color: palette.$interface_focus_primary;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_confirm {
            color: palette.$label_onsurface;
            background: palette.$action_confirm_primary;
            border-color: palette.$action_confirm_primary;
            &:hover {
                color: palette.$label_onsurface;
                background: palette.$action_confirm_secondary;
                border-color: palette.$action_confirm_secondary;
            }
            &:active {
                color: palette.$label_onsurface;
                background: palette.$action_confirm_tertiary;
                border-color: palette.$action_confirm_tertiary;
            }
            &:focus-visible {
                color: palette.$label_onsurface;
                background: palette.$action_confirm_primary;
                border-color: palette.$action_confirm_primary;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_error {
            color: palette.$label_onsurface;
            background: palette.$action_error_primary;
            border-color: palette.$action_error_primary;
            &:hover {
                color: palette.$label_onsurface;
                background: palette.$action_error_secondary;
                border-color: palette.$action_error_secondary;
            }
            &:active {
                color: palette.$label_onsurface;
                background: palette.$action_error_tertiary;
                border-color: palette.$action_error_tertiary;
            }
            &:focus-visible {
                color: palette.$label_onsurface;
                background: palette.$action_error_primary;
                border-color: palette.$action_error_primary;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_stroke {
            color: palette.$label_brand;
            background: palette.$interface_onsuface;
            border-color: palette.$label_brand  ;
            &:hover {
                color: palette.$label_secondary;
                background: palette.$interface_onsuface;
                border-color: palette.$interface_tertiary;
            }
            &:active {
                color: palette.$label_brand;
                background: palette.$interface_onsuface;
                border-color: palette.$interface_ad_tertiary;
            }
            &:focus-visible {
                color: palette.$label_secondary;
                background: palette.$interface_onsuface;
                border-color: palette.$interface_focus_primary;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_ghost {
            color: palette.$interface_quaternary;
            background: palette.$background_primary;
            border-color: palette.$interface_quaternary;
            &:hover {
                color: palette.$label_brand;
                background: palette.$background_primary;
                border-color: palette.$label_brand;

            }
            &:active {
                opacity: .8;
            }
            &:focus-visible {
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_grey {
            color: palette.$label_secondary;
            background: palette.$background_tertiary;
            border-color: palette.$background_tertiary;
            &:hover {
                color: palette.$label_brand;
            }
            &:active {
                color: palette.$label_brand;
            }
            &:focus-visible {
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_ghost_cancel {
            color: palette.$label_quaternary;
            background: none;
            border-color: transparent;
            &:hover {
                color: palette.$label_brand;
                background: #f3f0ff;
            }
            &:active {
                opacity: .8;
            }
            &:focus-visible {
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_ghost_error {
            color: palette.$label_error;
            background: none;
            border-color: transparent;
            &:hover {
                background: #fff0f0;
            }
            &:active {
                opacity: .8;
            }
            &:focus-visible {
                outline: 1px solid palette.$interface_focus_primary;
            }
        }

        &_ghost_empty {
            color: palette.$label_brand;
            background: none;
            border: none;

            &:hover {
                color: palette.$label_secondary;
            }
            &:active {
                color: palette.$label_secondary;
            }
            &:focus-visible {
                color: palette.$label_brand;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }
        &_empty {
            background: none;
            border-color: transparent;

            &:hover {
                color: palette.$label_brand;
                background: palette.$background_tertiary;
            }
            &:active {
                color: palette.$label_brand;
                background: palette.$background_tertiary;
            }
            &:focus-visible {
                color: palette.$label_brand;
                background: palette.$background_tertiary;
                outline: 1px solid palette.$interface_focus_primary;
            }
        }

        &_black {
            background: palette.$label_black;
            border-color: transparent;
            color: palette.$label_onsurface;

            &:hover {
                background: palette.$background_seventh;
            }
            &:active {
                background: palette.$background_seventh;
            }
            &:focus-visible {
                outline: 2px solid palette.$interface_focus_primary;
            }
        }
    }

    &:disabled,
    &.disabled {
        color: palette.$label_fifth;
        background: palette.$interface_seventh;
        border: 1px solid palette.$interface_seventh;
    }

    &_text {
        &_center {
            text-align: center;
        }
        &_left {
            text-align: left;
        }
        &_right {
            text-align: right;
        }
    }
    &_size {
        &_s {
            overflow: initial;
            display: flex;
            padding: 9px 9px;

            --btn-p: 9px 9px;
            --btn-fz: 14px;
            --btn-icon-left-mr: 0;
            //--btn-icon-size: 20px;
        }
        &_m {
            --btn-p: 10px 14px;
            --btn-fz: 13px;
            --btn-icon-left-mr: 10px;
            //--btn-icon-size: 20px;
        }
        &_l {
            --btn-p: 7px 16px;
            --btn-fz: 13px;
            --btn-icon-left-mr: 8px;
            //--btn-icon-size: 20px;
        }
        &_xl {
            //--btn-p: 12px 20px;
            //--btn-fz: 16px;
            //--btn-icon-left-mr: 10px;
        }
        &_xxl {
            --btn-p: 13px 24px;
            //--btn-fz: 16px;
            --btn-icon-left-mr: 14px;
            --btn-icon-size: 24px; //?
        }
    }

    &_kind {
        &_square {
            --btn-bdrs: 8px;
        }
        &_ellipse {
            --btn-bdrs: 26px;
        }
        &_round {
            --btn-bdrs: 50%;
        }
    }

    &__icon {
        width: var(--btn-icon-size, 24px);
        height: var(--btn-icon-size, 24px);
        vertical-align: top;
    }

    &__iconLeft {
        width: var(--btn-icon-size, 20px);
        height: var(--btn-icon-size, 20px);
        margin-right: var(--btn-icon-left-mr, 10px);
        vertical-align: top;
    }

    &_icon_right {
        &_regular {
            float: right;
            margin-left: var(--btn-icon-left-ml, 10px);
            vertical-align: top;
        }

        &_done {
            margin-left: var(--btn-icon-left-ml, 10px);
            color: palette.$label_brand;
            vertical-align: bottom;
        }
    }

    &__counter {
        margin-left: 8px;
    }

    &__header {
        padding-right: 20px;
    }

    &__fullWidth {
        width: 100%;
    }

    &__fullHeight {
        height: 100%;
    }

    &__absoluteIcon {
        border: none;
        padding-left: 55px;
        .btn__iconLeft {
            position: absolute;
            top: 0;
            left: 0;
            width: auto;
            height: auto;
        }
    }
}
