@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.details {
  align-self: stretch;
  display: flex;
  gap: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0 24px;
  padding: 40px 0;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: 0;
    padding: 0;
  }

  &__cashback {
    align-self: stretch;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background: #F2F3FF;
    border-radius: 26px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
      padding: 25px 40px;
      flex-grow: 1;
    }

    &_wrap {
      display: flex;
      gap: 30px;
      width: 100%;
      flex-direction: column;
    }
  }

  &__with_origin {
    color: palette.$label_fifth;
    text-decoration: line-through;
  }

  &__description {
    overflow-x: scroll;
    width: 100%;
  }

  &__info {
    color: palette.$label_tertiary;
    border-radius: 14px;
    border: 2px solid palette.$label_brand;
    display: flex;
    width: 100%;
    padding: 20px;
    flex-direction: column;
    justify-content: stretch;
    text-align: center;
    gap: 5px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 200px;
    }
  }
}


