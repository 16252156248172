@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content {
  align-self: stretch;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: 'count fulfilled' 'bonus bonus';
  gap: 15px;
  align-items: flex-start;
  grid-area: stats;


  @media (min-width: breakpoints.$desktop-02-lower-boundary) {
    gap: 20px;
    grid-template-areas: 'count fulfilled bonus';
  }
}

.item {
  display: flex;
  padding: 14px 30px;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  flex-direction: column;
  background: palette.$background_tertiary;
  border-radius: 14px;
  height: 100%;

  @media (max-width: breakpoints.$phone-upper-boundary) {
    &_ru {
      padding: 14px 10px;
    }
  }
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    flex-direction: initial;
    gap: 16px;
    height: 70px;
  }

  &_text {
    color: palette.$label_quaternary;
    text-align: center;
  }

  &_value {
    color: palette.$label_primary;
  }

  &_registered_count {
    grid-area: count;
  }

  &_fulfilled_condition {
    grid-area: fulfilled;
  }

  &_bonus_payment {
    grid-area: bonus;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      flex-direction: initial;
    }
  }
}
