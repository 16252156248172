@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;


.menu {
  &__header {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    padding: 0 24px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 20px;
    }
    &_user_info {
      align-self: stretch;
      overflow: hidden;
      display: flex;
      gap: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &_user_status {
      display: flex;
      align-items: center;
      height: 18px;
      padding: 0 6px 1px 6px;
      color: var(--link-c, palette.$label_onsurface);
      background: #030303;
      border-radius: 5px;
    }

    &_user_email {
      overflow: hidden;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: palette.$label_primary;
      text-overflow: ellipsis;
    }

  }

  &__list {
    width: 100%;
    padding: 0 24px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 0;
    }

    &_link {
      padding: 13px 0;
      border-bottom: 1px solid palette.$interface_sixth;

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        border: none;
      }

      &_chrome {
        width: 100%;
        padding: 13px 24px;
      }
    }

    &_header {
      display: flex;
      gap: 10px;
      align-items: flex-start;
      padding: 0 24px;

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        padding: 0 0 13px;
      }
    }

    &_logout {
      padding: 13px 24px;
      color: palette.$action_error_primary;

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        padding: 13px 0 0;
        border-top: 1px solid palette.$interface_sixth;
      }
    }
  }

  &__logout {
    padding: 13px 24px;
    color: palette.$action_error_primary;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 13px 20px;
    }
  }
}
