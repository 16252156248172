$background_primary: #fff;
$background_secondary: #fcfbfd;
$background_tertiary: #f7f7fa;
$background_quaternary: #efeff5;
$background_fifth: #948f98;
$background_sixth: #625c66;
$background_seventh: #29272b;


$interface_primary: #5a545f;
$interface_secondary: #6e6873;
$interface_tertiary: #8c8691;
$interface_quaternary: #b4aeb9;
$interface_fifth: #ddd9e0;
$interface_sixth: #f3eff5;
$interface_seventh: #f8f6fa;
$interface_onsuface: #fff;
$interface_ad_primary: #2302f5;
$interface_ad_secondary: #2d0cff;
$interface_ad_tertiary: #0f00e1;
$interface_ad_quaternary: #0000c3;
$interface_blue_primary: #57b9f0;
$interface_blue_secondary: #6bcdff;
$interface_blue_tertiary: #43a5dc;
$interface_blue_quaternary: #399bd2;
$interface_focus_primary: #2302f5;


$label_primary: #0b1116;
$label_secondary: #333a40;
$label_tertiary: #5a6065;
$label_quaternary: #888c8f;
$label_fifth: #b5b8bb;
$label_sixth: #d8dadc;
$label_seventh: #eceff1;
$label_onsurface: #fff;
$label_confirm: #7ec050;
$label_error: #e47470;
$label_notice: #d79735;
$label_link: #43a5dc;
$label_brand: #2302f5;
$label_black: #030303;


$action_confirm_primary: #7ec050;
$action_confirm_secondary: #92d464;
$action_confirm_tertiary: #6aac3c;
$action_error_primary: #e47470;
$action_error_secondary: #f88884;
$action_error_tertiary: #d0605c;
$action_notice_primary: #ebab49;
$action_notice_secondary: #ffbf5d;
$action_notice_tertiary: #d79735;

$background_action_confirm: #f2f9ec;
$background_action_notice: #fcf6ed;
$background_action_error: #fcf0f0;
$background_action_brand: #f2efff;
$background_action_link: #eef5fe;

$brand_light_primary: #c4c4c4;
