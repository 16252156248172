@use 'src/assets/styles/breakpoints' as breakpoints;

.offers {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 40px;
  }
}
