.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.cashback_img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
