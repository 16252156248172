@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.pageTitle {
    grid-area: title;
    display: flex;
    margin: 30px 24px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
        margin: 40px 0;
    }
}

.pageTitleIcon {
    margin-right: 10px;
    padding: 0;
    line-height: 0;
    background: none;
    border: none;
    cursor: pointer;
    &:focus-visible {
        outline: 2px solid palette.$interface_focus_primary;
    }
    &:hover {
        opacity: .5;
    }
    &:active {
        opacity: .7;
    }
}
