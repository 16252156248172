@use 'src/assets/styles/breakpoints' as breakpoints;

.stores {
  overflow-x: auto;
  display: flex;
  gap: 15px;
  align-items: stretch;
  width: 100%;
  max-width: 100%;


  @media (min-width: breakpoints.$desktop-lower-boundary) {
    overflow-x: inherit;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 40px;
  }
}
