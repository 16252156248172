@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.banner {
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
  padding: 30px 20px 10px;
  color: palette.$label_tertiary;
  background: url('../../../../assets/images/banners/banner-account-bg.png') left center / auto 100% no-repeat;
  border-radius: 26px;
  cursor: pointer;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    padding: 22px 30px;
  }

  &_description {
    display: grid;
    gap: 10px;
    width: 100%;
    @media (min-width: breakpoints.$desktop-02-upper-boundary) {
      width: 73%;
    }
  }
  &_title {
    color: palette.$label_primary;
  }
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
