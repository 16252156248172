@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.home {
  &__categories {
    overflow-x: auto;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    max-width: 100%;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      overflow-x: inherit;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: flex-start;
      row-gap: 40px;
    }
  }
}
