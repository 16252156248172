@use 'src/assets/styles/palette' as palette;

.emptyBlank {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
    background: palette.$background_secondary;
    border-radius: 8px;
}

.emptyImg {
    margin: 0 auto;
}

.emptyTitle {
    max-width: 490px;
    margin: 20px auto 0;
}

.emptyText {
    max-width: 490px;
    margin: 8px auto 0;
    color: palette.$label_tertiary;
}
