@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.products {
  overflow-x: auto;
  display: flex;
  gap: 15px;
  align-items: stretch;
  width: 100%;
  max-width: 100%;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    overflow-x: inherit;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    row-gap: 30px;
  }

  &_item {
    flex: 1 0 0;
    display: flex;
    gap: 26px;
    flex-direction: column;
    align-items: center;
    padding: 29px 19px;
    background: palette.$background_primary;
    border: none;
    border-radius: 26px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04), 0 12px 22px 0 rgba(0, 0, 0, 0.08);
    cursor: pointer;

    &_title {
      margin-bottom: 10px;
      color: palette.$label_quaternary;
    }
  }
}
