@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;


.blocks {
  display: grid;
  grid-template-columns: repeat(3, minmax(240px, 340px));
  gap: 40px;
  flex-wrap: wrap;
  @media (max-width: breakpoints.$phone-upper-boundary) {
    grid-template-columns: repeat(1, minmax(240px, 1fr));
    gap: 20px;
  }
}

.block {
  display: flex;
  gap: 10px;
  flex-direction: column;
  text-align: center;
}

.image {
  margin-bottom: 10px;
}

.title {
  color: palette.$label_primary;
}

.text {
  color: palette.$label_tertiary;
}
