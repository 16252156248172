@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;


.user_profile {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: auto;

  &__info {
    display: flex;
    gap: 2px;
    flex-direction: column;
    align-items: flex-start;
  }

  &__earnings {
    font-size: 13px;
    font-weight: 700;
    color: palette.$interface_ad_primary;
    cursor: pointer;

    &_awaiting {
      color: palette.$label_quaternary;
    }
  }

  &__logo_btn {
    position: relative;
    padding: 0;
    line-height: 0;
    background: none;
    border: none;
    cursor: pointer;

    &:focus-visible {
      outline: 2px solid palette.$interface_focus_primary;
    }
  }

  &__desktop_view {
    display: none;
    visibility: hidden;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      display: block;
      visibility: visible;
    }
  }

}
