@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offer_card_header {
  align-self: stretch;
  display: flex;
  align-items: center;
  padding: 15px 20px;

  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70px;
    background: palette.$background_primary;
    border-radius: 100px;

    &_img {
      max-width: 160px;
      max-height: 68px;
    }
  }

  &__hr {
    width: 200px;
    height: 1px;
    color: palette.$interface_sixth;
  }
}


