@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content {
  margin-bottom: 30px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-columns: 1fr 1fr;
  }
}

.checkbox_wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 11px;
}

.title {
  margin-bottom: 20px;
}

.button_wrapper {
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin-left: auto;
  }
}

.commission {
  display: inline-block;
  margin-top: 15px;
  font-size: 16px;
  color: palette.$label_notice;
}


