@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.header {
    background: #f9f9ff;

    &__content {
        display: flex;
        align-items: center;
        width: 100%;
        height: 80px;
        margin: 0 auto;
        border-bottom: 1px solid palette.$interface_sixth;
        @media (min-width: breakpoints.$desktop-lower-boundary) {
            gap: 40px;
        }
        @media (max-width: breakpoints.$tablet-upper-boundary) {
            gap: 12px;
            height: 60px;
            padding: 0 24px;
        }
    }

    &__logo {
        display: flex;
        margin: 0;
        font-size: 20px;
        font-weight: 900;
        color: palette.$label_secondary;
        text-decoration: none;
        &:focus-visible {
            outline: 2px solid palette.$interface_focus_primary;
        }

        &:hover {
            cursor: pointer;
        }
    }

}
