@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.actionCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  text-decoration: none;
  background: #f2f3ff;
  border: 2px solid #f2f3ff;
  border-radius: 26px;
}
