@use 'src/assets/styles/breakpoints' as breakpoints;

.layoutContainer {
  width: var(--common-page-width, 100%);
  margin: 0 auto;
  @media (min-width: breakpoints.$desktop-full-hd-lower-boundary) {
    --common-page-width: 1400px;
  }
  @media (min-width: breakpoints.$desktop-hd-lower-boundary) {
    --common-page-width: 1360px;
  }
  @media (min-width: breakpoints.$desktop-02-lower-boundary) and (max-width: breakpoints.$desktop-02-upper-boundary) {
    --common-page-width: 1170px;
  }
  @media (min-width: breakpoints.$desktop-lower-boundary) and (max-width: breakpoints.$desktop-01-upper-boundary) {
    --common-page-width: 988px;
  }
}
