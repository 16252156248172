@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

.footer {
    margin-top: auto;
    color: palette.$label_onsurface;
    text-align: center;
    background: #343434;

    &__top {
        border-bottom: 1px solid #404040;
        &Wrapper {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 100%;
            height: 80px;
            margin: 0 auto;

            @media (max-width: breakpoints.$tablet-upper-boundary) {
                flex-direction: column;
                align-items: self-start;
                height: auto;
                padding: 20px 24px;
                row-gap: 20px;
            }

            &_logo_btn {
                height: 35px;
                @media (max-width: breakpoints.$tablet-upper-boundary) {
                    height: auto;
                }
            }
        }
    }

    &__bottom {
        &Wrapper {
            display: flex;
            gap: 30px;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            height: 48px;
            margin: 0 auto;

            @media (max-width: breakpoints.$tablet-upper-boundary) {
                flex-direction: column;
                align-items: self-start;
                height: auto;
                padding: 20px 24px;
                row-gap: 20px;
            }
        }
        &_moblie {
            height: 58px;
        }
    }

    &__logo {
        display: flex;
        margin-right: auto;
        font-size: 20px;
        font-weight: 900;
        color: palette.$label_secondary;
        text-decoration: none;
        &:focus-visible {
            outline: 21x solid palette.$interface_focus_primary;
        }

        @media (max-width: breakpoints.$tablet-upper-boundary) {
            width: 95px;
        }
    }

    &__langWrapper {
        display: flex;
        align-items: center;
        @media (min-width: breakpoints.$desktop-lower-boundary) {
            margin-left: auto;
        }
    }

    &__langTitle {
        margin-right: 20px;
        color: palette.$label_sixth;
    }

    &__links {
        display: flex;
        gap: 40px;
        align-items: center;

        @media (max-width: breakpoints.$tablet-upper-boundary) {
            align-self: stretch;
            display: flex;
            gap: 10px 40px;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
        }
    }

    &__linksTerms {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: center;
    }

    &__link {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: palette.$label_fifth;
        }

        &:focus-visible {
            outline: 1px solid palette.$interface_focus_primary;
        }
    }

    &__socialLinks {
        display: flex;
        gap: 10px;
        color: #fff;

        @media (min-width: breakpoints.$desktop-lower-boundary) {
            margin-left: auto;
        }
    }

    &__socialLink {
        color: #fff;
        &:hover {
            color: palette.$interface_ad_primary;
        }
    }
}
