@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.region {
  display: flex;
  align-items: center;
  padding: 6px;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  gap: 4px;

  &:focus-visible {
    outline: 2px solid palette.$interface_focus_primary;
  }
}
