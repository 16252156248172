@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

.footer {
    z-index: 2;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding: 28px 17px;
    text-decoration: none;
    background: palette.$background_tertiary;
}

.link {
    color: palette.$label_quaternary;
    text-decoration: none;

    &:hover {
        color: palette.$label_tertiary;
    }
}
