@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offer_card_footer {
  align-self: stretch;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  padding: 10px 20px 20px 20px;
  background: palette.$background_primary;
  border-bottom-right-radius: 26px;
  border-bottom-left-radius: 26px;

  &__period {
    align-self: stretch;
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    color: palette.$label_quaternary;
  }

  &__btn {
    margin-left: auto;
  }
}
