@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin: 0 24px 0;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: 0;
  }
}
