.controls {
    display: flex;
    gap: 10px;
    &_align {
        &_right {
            justify-content: flex-end;
        }
        &_justify {
            justify-content: space-between;
        }
        &_vertical {
            flex-direction: column;
        }
    }
}
