@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.categoryCard {
  flex-shrink: 0;
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  width: 130px;
  height: 130px;
  padding: 16px;
  color: palette.$label_secondary;
  text-align: center;
  text-decoration: none;
  background: var(--cat-bg, #f3f4fc);
  border-radius: 26px;

  &:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.04), 0 12px 22px 0 rgba(0, 0, 0, 0.08);
    --cat-bg: palette.$background_primary;
  }

  &:focus-visible {
    outline: 2px solid palette.$interface_focus_primary;
  }

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    gap: 16px;
    flex-direction: row;
    width: 200px;
    height: 98px;
    padding: 20px;
    text-align: left;
  }
}
