@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.referral {
  grid-area: referral;
  color: palette.$label_secondary;

  &__title {
    margin-bottom: 10px;
    color: palette.$label_tertiary;
  }

  &_copy {
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin-top: 20px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 10px;
      flex-direction: initial;
    }

    &__btn {
      height: fit-content;
    }

    &__field {
      flex: 1 0 0;
    }
  }
}

.referralStats {
  grid-area: stats;
}

.referralFaq {
  grid-area: faq;
}

.referralSocial {
  align-self: flex-start;
  max-height: 44px;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    align-self: flex-end;
    margin-left: 20px;
  }
}

.content {
  display: grid;
  grid-template-areas: 'referral' 'stats' 'rhelp' 'faq';
  margin-bottom: 30px;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-areas: 'stats' 'referral' 'rhelp' 'faq';
  }
}




