
$modal_indents: 15px;

.noScroll {
  overflow: hidden !important;
}

// Контейнер который во всю высоту и ширину контентной области
.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Дополнительный контейнер, который контролирует высоту модалки
// И внутри которого будет скроллиться контент модалки
// Через один контейнер сделать не получалось, т.к. модалка вылезала наружу
.modalContentContainer {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

