@use 'src/assets/styles/palette' as palette;

.link {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0;
    font-size: 14px;
    line-height: 1.3;
    text-decoration: none;
    background: none;
    border: none;
    cursor: pointer;

    &_theme {
        &_fill {
            font-weight: 500;
            color: palette.$label_secondary;
            &:hover {
                color: palette.$label_brand;
            }
            &:active {
                color: palette.$label_brand;
            }
        }
        &_stroke {
            font-weight: 400;
            color: palette.$label_tertiary;
            &:hover {
                color: palette.$label_brand;
            }
            &:active {
                color: palette.$label_brand;
            }
            &_confirm {
                font-weight: 400;
                color: palette.$label_brand;
                &:hover {
                    color: palette.$label_secondary;
                }
                &:active {
                    color: palette.$label_secondary;
                }
            }
        }
        &_error {
            font-weight: 400;
            color: palette.$action_error_primary;
            &:hover {
                color: palette.$action_error_tertiary;
            }
            &:active {
                color: palette.$action_error_tertiary;
            }
        }

        &_empty {
            color: palette.$label_brand;
            &:hover {
                color: palette.$label_secondary;
            }
            &:active {
                color: palette.$label_secondary;
            }
        }


        &_menu {
            padding: 13px 20px;
            font-weight: 500;
            color: palette.$label_secondary;
            &:hover {
                color: palette.$label_brand;

                .link__icon_theme_tertiary {
                    color: palette.$label_brand;
                }
            }
            &:active {
                color: palette.$label_secondary;
                background: palette.$background_tertiary;
                border-radius: 12px;
                .link__icon_theme_tertiary {
                    color: palette.$label_brand;
                }
            }

            &_confirm {
                padding: 13px 20px;
                font-weight: 500;
                color: palette.$label_secondary;
                background: palette.$background_tertiary;
                border-radius: 12px;

                .link__icon_theme_tertiary {
                    color: palette.$label_brand;
                }
                &:hover {
                    color: palette.$label_brand;
                }
                &:active {
                    color: palette.$label_brand;
                }
            }
        }
    }
    &:focus-visible {
        outline: 2px solid palette.$interface_focus_primary;
    }

    &__icon {
        vertical-align: top;
        &_left {
            //margin-right: var(--btn-icon-left-mr, 10px);
        }
        &_theme {
            &_fill {
            }
            &_tertiary {
                 color: palette.$interface_tertiary;
            }
        }
        &_size {
            &_s {
                width: 20px;
                height: 20px;
            }
            &_m {
                width: 24px;
                height: 24px;
            }
            &_l {
                width: 28px;
                height: 28px;
            }

        }
    }
}


.link_with_arrow {
    &:before {
        content: "";
        position: absolute;
        right: 0;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-right: 35px;
        opacity: 0.8;
        border-bottom: 1px solid palette.$interface_quaternary;
        border-left: 1px solid palette.$interface_quaternary;
        transform: rotate(-135deg);
        transition: opacity 0.3s;
    }
}
