@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.dialog {
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: flex-start;

  &__header {
    display: grid;
    gap: 15px;
    &_title {
      display: flex;
    }
    &_region {
      color: palette.$label_brand;
    }
  }
  &__content {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    &_list {
      width: 100%;
    }

    &_note {
      color: palette.$label_brand;
    }
  }

  &__footer {
    align-self: stretch;
    align-items: center;

    &_btn_confirm {
      margin-left: auto;
    }
    @media (max-width: breakpoints.$tablet-upper-boundary) {
      position: sticky;
      bottom: 0;
      background: palette.$background_primary;
    }
  }

  &__countries_wrap {
    display: grid;
    gap: 15px;

    &_other {
      display: flex;
      gap: 10px;
      flex-direction: column;
      .dialog__countries {
        grid-template-columns: 1fr auto;
      }
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        display: flex;
        gap: 15px;
        flex-direction: initial;
        align-content: center;
      }
    }
  }
  &__countries_text {
    display: grid;
    flex: 10px
  }

  .page_title_icon {
    margin-right: 10px;
    padding: 0;
    line-height: 0;
    background: none;
    border: none;
    cursor: pointer;
    &:focus-visible {
      outline: 2px solid palette.$interface_focus_primary;
    }
    &:hover {
      opacity: .5;
    }
    &:active {
      opacity: .7;
    }
  }

  &__countries {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 15px;
    row-gap: 10px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      grid-template-columns: repeat(3, 200px);
    }
  }

  &__text {
    color: palette.$label_quaternary;
  }

  &__img_icon {
    margin-right: 10px;
    vertical-align: text-top;
  }
}
