@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offer_card_body {
  align-self: stretch;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background: palette.$background_primary;
  border-radius: 26px;
  @media (min-width: breakpoints.$desktop-lower-boundary) {
    border-radius: 26px 26px 0 0;
  }
  &__title {
    align-self: stretch;
    display: flex;
    gap: 8px;
    justify-content: space-between;
    align-items: center;

    &_cashback {
      display: inline-block;
      font-size: 16px;
      color: #e40066;
      text-align: right;
    }
  }

  &__desc {
    overflow: hidden;
    display: -webkit-box;
    height: 40px;
    color: palette.$label_tertiary;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  &__with_origin {
    text-decoration: line-through;
    color: palette.$label_fifth;
  }

}
