@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.card {
  display: grid;
  grid-template-areas: 'status''promo' 'cashback' 'date';
  gap: 15px;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  color: palette.$label_secondary;
  background: palette.$background_primary;
  border: 2px solid #F2F3FF;

  border-radius: 16px;
  &_inactive {
    color: palette.$label_quaternary;
  }

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-areas: 'promo cashback status date';
  }

  &__status {
    grid-area: status;
  }

  &__date {
    grid-area: date;
    display: flex;
    gap: 4px;
    align-items: flex-end;


    @media (min-width: breakpoints.$desktop-lower-boundary) {
      min-width: 145px;
    }
  }

  &__promo {
    grid-area: promo;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      min-width: 175px;
    }
  }

  &__cashback {
    grid-area: cashback;
  }

  &__cc {
    color: palette.$action_confirm_primary;
  }

  &__ce {
    color: palette.$label_error;
  }

}
