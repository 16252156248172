@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.storeCard {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  width: 150px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  background: palette.$background_primary;
  border: 1px solid palette.$interface_fifth;
  border-radius: 26px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    width: auto;
    padding: 20px;
  }

  &__logo_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 50px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 160px;
      height: 68px;
    }
  }
  &__logo {
    max-width: 122px;
    max-height: 50px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      max-width: 160px;
      max-height: 68px;
    }
  }

  &__text {
    color: var(--store-text-c, palette.$label_secondary);
  }
  &__name {
    color: palette.$label_quaternary;
  }

  &:hover {
    .storeCard__text {
      --store-text-c: $label_brand;
    }
  }

  &:focus-visible {
    outline: 2px solid palette.$interface_focus_primary;
  }
}
