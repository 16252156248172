@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.amount {
  display: flex;
  gap: 20px;
  flex-direction: column;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin-top: 40px;
  }

  &__link {
    color: palette.$interface_ad_primary;
    text-decoration: none;
  }

  &__title {
    color: palette.$label_tertiary;
  }
  &__input {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }

  &__first_cashback {
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: palette.$label_tertiary;
    border: 2px solid palette.$interface_ad_primary;
    border-radius: 14px;
    &_title {
      color: palette.$background_seventh;
    }
  }

  &__not_enough {
    display: flex;
    gap: 20px;
    flex-direction: column;
    padding: 20px;
    background: palette.$background_tertiary;
    border-radius: 14px;

    &_text {
      align-self: stretch;
      display: flex;
      gap: 10px;
      flex-direction: column;
      align-items: center;
      color: palette.$label_tertiary;

      &_title {
        color: palette.$background_seventh;
      }
    }
  }
}


