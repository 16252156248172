@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.content_wrapper {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 24px 30px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin: 0;
    padding: 0;
  }
}
.content {
  align-self: stretch;
  display: flex;
  align-items: flex-start;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    gap: 20px;
  }

  &__item {
    flex: 1 1 0;
    display: flex;
    gap: 4px;
    justify-content: center;
    padding: 14px 0;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      align-self: stretch;
      gap: 16px;
      justify-content: left;
      align-items: center;
      padding: 14px 30px;
    }

    &_info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media (min-width: breakpoints.$desktop-lower-boundary) {
        align-items: flex-start;
      }
      &_text {
        color: palette.$label_quaternary;
      }

      &_value_brand {
        color: palette.$label_brand;
      }
      &_btn {
        margin-left: auto;
      }
    }
  }
  &__awaiting {
    background: palette.$background_quaternary;
    border-radius: 0 14px 14px 0;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      border-radius: 14px;
    }
  }

  &__confirmed {
    background: palette.$background_tertiary;
    border-radius: 14px 0 0 14px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      border-radius: 14px;
    }
  }

  &__btn {

  }
}


