@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.info {
  position: relative;
  z-index: 1;
  display: grid;
  gap: 10px;
  justify-items: left;
  max-width: 695px;
  height: 100%;

  @media (max-width: breakpoints.$tablet-upper-boundary) {
    gap: 20px;
    justify-items: center;
    width: 100%;
  }
  &_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background: palette.$background_primary;
    border-radius: 12px;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      width: 100%;
    }
    &_img {
      max-width: 210px;
      max-height: 80px;
    }
  }
  &_title {
    font-size: 60px;
    line-height: 1.1;
    color: palette.$label_primary;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      font-size: 40px;
      text-align: center;
    }
  }

  &_text {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: palette.$label_secondary;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      overflow: hidden;
      font-size: 28px;
      text-overflow: ellipsis;
    }

    &_welcome {
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        margin-bottom: 52px;
      }
    }

    &_takeAdsV1, &_takeAdsV2 {
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        font-size: 40px;
        margin-bottom: 30px;
      }
    }
  }

  &_btns {
    display: flex;
    gap: 20px;
    &_wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 20px;
    }
  }

  &_hidden {
    display: none;
    opacity: 0;
  }
}
