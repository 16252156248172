@use 'src/assets/styles/palette' as palette;

.actions_table {
  width: 100%;
  margin-bottom: 40px;
  background: palette.$background_tertiary;
  border: 2px solid palette.$background_tertiary;
  border-spacing: inherit;
  border-radius: 26px;
}

.actions_table__header {
  &_tr {
    padding: 14px 20px;
    font-weight: 600;
    color: palette.$label_quaternary;
  }
  &_th {
    padding: 14px 20px;
    text-align: left;

    &_last {
      text-align: right;
    }
  }
}

.actions_table__body {
  border: 2px solid palette.$background_tertiary;

  &_tr {
    padding: 20px;

    background: palette.$background_primary;

    &:first-child {
      .actions_table__body_td {
        &:first-child {
          border-radius: 26px 0 0 0;
        }
        &:last-child {
          border-radius: 0 26px 0 0;
        }
      }
    }

    &:last-child {
      .actions_table__body_td {
        border-bottom: none;

        &:first-child {
          border-radius: 0 0 0 26px;
        }
        &:last-child {
          border-radius: 0 0 26px 0;
        }
      }
    }
  }

  &_td {
    padding: 20px;
    text-align: left;
    border-bottom: 2px solid palette.$background_tertiary;
  }
}

.amount_text {
  color: palette.$label_tertiary;
  text-align: right;

  &__approved {
    color: palette.$label_brand;
    text-align: right;
  }

  &__declined {
    color: palette.$label_error;
    text-align: right;
  }
}

.action_card_link {
  color: inherit;
  text-decoration: none;
}
