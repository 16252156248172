.landing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  margin-top: 100px;
}

.container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 45px;
}

.subtitle {
  font-size: 26px;
  font-weight: 400;
}

.infoGraphic {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
}
