@use 'palette' as palette;

@mixin headerText($fz, $fw) {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: $fz;
    font-weight: $fw;
    line-height: 1.2;
    // color: palette.$label_secondary;
    word-break: break-word;
}

@mixin bodyText($fz, $fw: 400) {
    font-style: normal;
    font-size: $fz;
    font-weight: $fw;
    line-height: 1.4;
}

@mixin buttonText($fz, $fw: 500) {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: $fz;
    font-weight: $fw;
    line-height: 1;
}

.textHeader_01 {
    @include headerText(60px, 700);
}

.textHeader_02 {
    @include headerText(48px, 700);
}

.textHeader_03 {
    @include headerText(36px, 600);
}

.textHeader_04 {
    @include headerText(32px, 600);
}

.textHeader_05 {
    @include headerText(28px, 600);
}

.textHeader_06 {
    @include headerText(22px, 600);
}

.textHeader_07 {
    @include headerText(20px, 600);
}

.textHeader_08 {
    @include headerText(18px, 600);
}

.textHeader_09 {
    @include headerText(15px, 600);
}

.textHeader_10 {
    @include headerText(14px, 600);
}

.textHeader_11 {
    @include headerText(12px, 500);
}

.textBody_01 {
    @include bodyText(18px);
}

.textBody_02 {
    @include bodyText(15px);
}

.textBody_03 {
    @include bodyText(14px);
}

.textBody_04 {
    @include bodyText(13px);
}

.textBody_05 {
    @include bodyText(12px);
}

.textBody_06 {
    @include bodyText(11px);
}

.textBody_07 {
    @include bodyText(10px);
}

.textBodyMedium_L {
    @include bodyText(26px, 700);
}

.textBodyMedium_01 {
    @include bodyText(18px, 500);
}

.textBodyMedium_02 {
    @include bodyText(15px, 500);
}

.textBodyMedium_03 {
    @include bodyText(14px, 500);
}

.textBodyMedium_04 {
    @include bodyText(13px, 500);
}

.textBodyMedium_05 {
    @include bodyText(12px, 500);
}

.textBodyMedium_06 {
    @include bodyText(11px, 500);
}

.textBodyMedium_07 {
    @include bodyText(10px, 500);
}

.textButton_01 {
    @include buttonText(17px)
}

.textButton_02 {
    @include buttonText(15px)
}

.textButton_03 {
    @include buttonText(13px)
}

.textButton_04 {
    @include buttonText(9px)
}
