@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  color: palette.$label_secondary;
  text-decoration: none;
  background: palette.$background_primary;
  border: 2px solid palette.$label_brand;
  border-radius: 26px;
  cursor: pointer;

  &_header {
    display: flex;
    height: 80px;
    padding: 10px 20px;

    &_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      line-height: 150%;
    }
  }
  &_body {
    display: grid;
    gap: 10px;
    padding: 20px;
    text-align: left;
    &_text {
      color: palette.$label_tertiary;
    }
  }
  &_footer {
    width: 100%;
    padding: 10px 20px 20px 20px;
  }

  &_hr {
    width: 200px;
    height: 1px;
    color: #f3eff5;
  }
}
