@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.offer_header {
  grid-area: title;
  align-self: stretch;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin: 0 24px;
  padding: 20px 0 40px 0;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    gap: 40px;
    flex-direction: inherit;
    margin: 0;
    padding: 60px 0;
  }

  &__logo {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    border: 1px solid palette.$interface_fifth;
    border-radius: 12px;
    background: none;
    cursor: pointer;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      width: 200px;
      padding: 20px;
    }

    &_img {
      @media (min-width: breakpoints.$desktop-lower-boundary) {
        max-width: 190px;
      }
    }
  }

  &__content {
    display: flex;
    gap: 10px;
    flex-direction: column;

    &_text {
      color: palette.$label_tertiary;
    }
  }

  &__button {
    margin-left: auto;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      width: 100%;
    }
  }

  &__with_origin {
    text-decoration: line-through;
    color: palette.$label_fifth;
  }
}


