@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.banner {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  align-items: flex-start;
  margin: 0;
  padding: 60px;
  color: palette.$label_tertiary;
  background: url('../../../../assets/images/banners/banner-main-bg.png') left center /auto 100% no-repeat;
  border-radius: 26px;
  @media (max-width: breakpoints.$tablet-upper-boundary) {
    grid-template-columns: 1fr;
    grid-template-areas: 'content' 'image';
    padding: 30px 20px;
  }

  &_content {
    display: flex;
    gap: 20px;
    flex-direction: column;
    width: 100%;
    text-align: left;

    @media (max-width: breakpoints.$tablet-upper-boundary) {
      grid-area: content;
    }
  }

  &_title {
    color: palette.$label_primary;
  }
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  @media (max-width: breakpoints.$tablet-upper-boundary) {
    grid-area: image;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }
}

.image {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
}
