@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.limits {
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin-bottom: 30px;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    margin-bottom: 60px;
  }
  &__title {
    color: palette.$label_tertiary;
  }

  &__dl {
    display: grid;
    font-size: 15px;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      grid-template-columns: 1fr 1fr;
      border: 1px solid palette.$interface_fifth;
      border-radius: 10px;
    }
    }

  &__dd {
    padding: 20px 0 10px;
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 20px;
      color: palette.$label_tertiary;
      border-bottom: 1px solid palette.$interface_fifth;
      &_last {
        border: none;
      }
    }
  }

  &__dt {
    padding: 0 0 20px;
    border-bottom: 1px solid palette.$interface_fifth;
    &:last-child {
      border-bottom: none;
    }
    @media (min-width: breakpoints.$desktop-lower-boundary) {
      padding: 20px;
      font-weight: 500;
      border-left: 1px solid palette.$interface_fifth;
      &:last-child {
        border-left: 1px solid palette.$interface_fifth;
      }
    }
  }
}


