@use 'src/assets/styles/palette' as palette;
@use 'src/assets/styles/breakpoints' as breakpoints;

.categoriesMenu {
    background: palette.$background_primary;
    &__top {
        border-bottom: 1px solid palette.$interface_sixth;

        &_wrapper {
            display: flex;
            gap: 40px;
            align-items: center;
            width: 100%;
            height: 66px;
        }
    }

    &__bottom {
        border-bottom: 1px solid palette.$interface_sixth;

        &_wrapper {
            flex-shrink: 0;
            display: flex;
            gap: 20px 60px;
            flex-wrap: wrap;
            align-content: flex-start;
            align-items: flex-start;
            padding: 30px 0;
        }

        &_link {
            width: 275px;
            padding: 4px 20px 4px 0;
        }
    }

    &__link {
        display: flex;
        gap: 8px;
        align-items: center;
        color: palette.$label_secondary;
        text-decoration: none;
    }

    &__shadow {
        box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.03), 0 3px 5px 0 rgba(0, 0, 0, 0.05);
    }

}
