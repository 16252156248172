@use 'src/assets/styles/breakpoints' as breakpoints;
@use 'src/assets/styles/palette' as palette;

.help {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-areas: 'one' 'two' 'three';
  gap: 15px;
  width: 100%;
  counter-reset: cards-item;

  @media (min-width: breakpoints.$desktop-lower-boundary) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: 'one two three';
    gap: 20px;
  }

  &__wrapper {
    display: flex;
    gap: 15px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    grid-area: rhelp;

    @media (min-width: breakpoints.$desktop-lower-boundary) {
      gap: 20px;
    }
  }

  &__title {
    color: palette.$label_tertiary;
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 20px;
    color: var(--help-color, palette.$interface_onsuface);
    background: var(--help-bg, #2302F5);
    border-radius: 14px;
    backdrop-filter: blur(15px);

    &:before {
      content: counter(cards-item);
      position: absolute;
      top: var(--item-top, -70px);
      right: var(--item-right, 35px);
      z-index: -1;
      font-size: 180px;
      font-weight: 700;
      line-height: 1.5;
      color: var(--item-c, #1700AC);
      counter-increment: cards-item;
    }

    &_text1 {
      width: 65%;
    }
    &_text2 {
      width: 220px;
      @media ((min-width: breakpoints.$desktop-lower-boundary) and (max-width: breakpoints.$desktop-01-upper-boundary)) {
        width: 100%;
      }
    }
    &_text3 {
      width: 224px;
      @media ((min-width: breakpoints.$desktop-lower-boundary) and (max-width: breakpoints.$desktop-01-upper-boundary)) {
        width: 100%;
      }
    }

    &_one {
      grid-area: one;
    }

    &_two {
      grid-area: two;
      --help-bg: rgba(236, 241, 255, 0.40);
      --help-color: palette.$label_primary;

      &:before {
        --item-c: #E5E7ED;
        --item-right: 14px;
        --item-top: -69px;
      }
    }

    &_three {
      grid-area: three;
      &:before {
        --item-right: 5px;
        --item-top: -69px;
      }
    }
  }
}
